
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import { IUser } from '@/components/user/user-model'

@Options({
  components: { UserAvatar },
  directives: { maska },
  emits: ['update:modelValue', 'update:change'],
})
export default class FilterUserInput extends Vue {
  @Prop({ default: [] })
  modelValue!: string[]

  @Prop({ default: [] })
  users!: IUser[]

  @Prop({ default: 'Users' })
  label!: string

  @Prop()
  icon!: string

  get value() {
    return this.modelValue
  }

  set value(value: string[]) {
    this.$emit('update:modelValue', value)
    this.$emit('update:change', value)
  }

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get selectedCount() {
    return this.value.length
  }

  toggleSelect(user: IUser) {
    const value = this.value
    if (!value?.length) {
      this.value = []
    }

    const index = value.indexOf(user._id as string)

    if (index >= 0) {
      value.splice(index, 1)
    } else {
      value.push(user._id as string)
    }

    this.value = value
  }
}
