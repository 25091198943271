<template>
  <q-btn text-color="white" no-caps padding="0 10px" align="left" :style="{ background: selected?.color ?? 'grey' }">
    <q-icon name="low_priority" />
    <span class="q-ml-sm">{{ selected?.label || 'unknown' }}</span>

    <q-menu style="min-width: 200px" auto-close>
      <q-list separator dense class="q-list--no-padding">
        <q-item
          clickable
          v-ripple
          v-for="option in workflowStates"
          :key="option.value"
          @click="toggleSelect(option)"
          :active="isActive(option.value)"
        >
          {{ option.label }}
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { ProjectModel } from '@/components/project/project-model'
import { IWorkflowState, WorkflowModel } from '@/components/workflow/workflow-model'

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class WorkflowStateSelect extends Vue {
  @Prop({ default: '' })
  modelValue!: string

  @Prop({ default: {} })
  project!: ProjectModel

  get value() {
    return this.modelValue
  }

  set value(value: string) {
    this.$emit('update:modelValue', value)
  }

  get workflows(): WorkflowModel[] {
    // return this.$store.getters.workflows(this.workspaceId)
    return []
  }

  get workflowStates(): IWorkflowState[] {
    return this.project?.workflow?.states || []
  }

  get selected(): IWorkflowState | undefined {
    return this.workflowStates.find(item => item.value === this.value)
  }

  isActive(selectedValue: string) {
    return this.value === selectedValue
  }

  toggleSelect(option: IWorkflowState) {
    this.value = option.value
  }

  created() {
    // if (!this.modelValue && this.workflows.length) {
    //   this.value = this.workflows[0].value
    // }
  }
}
</script>
