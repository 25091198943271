import { render } from "./TaskListTable.vue?vue&type=template&id=aa26f1f2"
import script from "./TaskListTable.vue?vue&type=script&lang=ts"
export * from "./TaskListTable.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBadge from 'quasar/src/components/badge/QBadge.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';import Ripple from 'quasar/src/directives/Ripple.js';
import ClosePopup from 'quasar/src/directives/ClosePopup.js';
import qInstall from "../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBadge,QSpace,QBtn,QMenu,QList,QItem,QItemSection,QIcon});qInstall(script, 'directives', {Ripple,ClosePopup});
