
import { mixins, Options } from 'vue-class-component'
import { Prop, Watch } from 'vue-property-decorator'
import { maska } from 'maska'

import { formatDateTime } from '@/utils/helpers'
import { ETaskState, ITask, TaskModel } from '@/components/task/task-model'

import cloneDeep from 'lodash/cloneDeep'
import TaskMixin from '@/components/task/mixins/TaskMixin.vue'
import CustomFieldMixin from '../custom-field/mixins/CustomFieldMixin.vue'
import CLoading from '../common/ui/CLoading.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import TaskApi from '@/components/task/task-api'
import logging from '@/utils/logging'
import NotFound from '../common/NotFound.vue'
import UserBox from '../user/ui/UserBox.vue'
import WorkflowStateSelect from '../workflow/ui/WorkflowStateSelect.vue'
import TaskComments from '../comment/ui/TaskComments.vue'
import TaskCommentForm from '../comment/ui/TaskCommentForm.vue'

@Options({
  components: {
    CLoading,
    NotFound,
    UserAvatar,
    UserBox,
    TaskComments,
    WorkflowStateSelect,
  },
  directives: { maska },
  emits: ['update:closeModal'],
})
export default class TaskViewModal extends mixins(TaskMixin, CustomFieldMixin) {
  @Prop({ default: {} })
  taskData!: TaskModel

  @Prop()
  modalVisible!: boolean

  task!: TaskModel

  componentReady = false
  isNotFound = false
  form: ITask = {}

  formatDateTime = formatDateTime

  get loading() {
    return !this.componentReady
  }

  get visible() {
    return this.modalVisible
  }

  set visible(value) {
    this.$emit('update:closeModal', value)
  }

  get taskId() {
    return this.taskData?._id
  }

  get project() {
    return this.$store.getters.project
  }

  get customFields() {
    return this.project?.form?.fields || []
  }

  get inputComment() {
    return this.$refs.commentForm
  }

  setActiveFormComment() {
    const commentForm = this.$refs.commentForm as TaskCommentForm
    if (commentForm) {
      commentForm.setActiveForm()
    }
  }

  @Watch('taskId', { immediate: true })
  async taskIdChanged() {
    if (this.taskId) {
      const task = await TaskApi.single(this.taskId)
      this.componentReady = true
      if (!task?._id) {
        this.isNotFound = true
        return
      }

      this.task = task
      this.form = { ...this.form, ...cloneDeep(task.sertialize()) }
      this.updateTaskSeen(task)
    }
  }

  onUpdateState(newState: ETaskState) {
    // [TaskMixin]
    this.updateTask({ _id: this.taskId, state: newState })
  }

  onCancel() {
    this.visible = true
  }

  beforeMount() {
    logging.debugRender(TaskViewModal.name)
  }
}
