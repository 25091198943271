<template>
  <q-tabs no-caps mobile-arrows dense inline-label align="left" class="q-tabs--gapping max-w-full-pt q-px-md q-my-xs">
    <q-input
      outlined
      dense
      rounded
      v-model="value.searchQuery"
      placeholder="task title"
      class="q-field--dense-smaller min-w-40"
    >
      <template v-slot:append>
        <q-icon name="search" />
      </template>
    </q-input>
    <FilterUserInput v-model="value.creatorIds" :users="projectMembers" :icon="'assignment_ind'" :label="'Creators'" />
    <FilterHiddenTaskColumns v-if="showHiddenFilter" v-model="value.hiddenColumns" :icon="'visibility_off'" />
    <q-space />
    <WorkspaceTopBar />
  </q-tabs>
  <q-separator />
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IUserWithPermission } from '@/components/user/user-model'
import UsersAvatar from '@/components/user/ui/UsersAvatar.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import FilterHiddenTaskColumns from './FilterHiddenTaskColumns.vue'
import FilterUserInput from '../../user/ui/FilterUserInput.vue'
import WorkspaceTopBar from '../../workspace/ui/WorkspaceTopBar.vue'
import logging from '@/utils/logging'

@Options({
  components: {
    WorkspaceTopBar,
    FilterUserInput,
    FilterHiddenTaskColumns,
    UserAvatar,
    UsersAvatar,
  },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class TopbarTasksFilter extends Vue {
  @Prop({ default: {} })
  modelValue!: string

  @Prop({ default: true })
  showHiddenFilter!: boolean

  get value() {
    return this.modelValue
  }

  get projectMembers(): IUserWithPermission[] {
    return this.$store.getters.project?.members || []
  }

  created() {
    logging.debugRender(TopbarTasksFilter.name)
  }
}
</script>
