
import { Options, mixins } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import { formatDate } from '@/utils/helpers'
import { TaskModel } from '@/components/task/task-model'

import TaskMixin from './TaskMixin.vue'
import TaskDisplayFilterMixin from './TaskDisplayFilterMixin.vue'

@Options({
  components: {},
})
export default class TaskDisplayMixin extends mixins(TaskMixin, TaskDisplayFilterMixin) {
  modalTaskFormVisible = false

  selected: TaskModel | null = null
  formatDate = formatDate

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get taskId() {
    return this.$route.params.taskId as string
  }

  set taskId(taskId: string) {
    if (!this.projectId) {
      return
    }

    this.$router.push({
      name: 'workspace_board.tasks',
      params: { taskId },
    })
  }

  get projectId() {
    return this.$route.params.projectId
  }

  get tasks(): TaskModel[] {
    return this.$store.getters.tasks(this.projectId)
  }

  get projectsColorMap() {
    return this.$store.getters.projectsColorMap(this.workspaceId)
  }

  get tasksFiltered(): TaskModel[] {
    return this.tasks.filter((task: TaskModel) => {
      // Match by search keyword
      const toSearch = task.title?.toLowerCase() || ''
      const keyword = this.filteredTasks.searchQuery.toLowerCase()
      const match1 = !this.filteredTasks.searchQuery || toSearch.indexOf(keyword) >= 0

      // Match by creator
      const creatorIds = this.filteredTasks?.creatorIds || []
      const match2 = !creatorIds.length || !task.creatorId || creatorIds.includes(task.creatorId)

      // Selected projects only
      return match1 && match2
    })
  }

  @Watch('taskId', { immediate: true })
  taskIdChanged() {
    this.modalTaskFormVisible = !!this.taskId
    this.selected = new TaskModel({ _id: this.taskId })
  }

  onDeleteTask(task: TaskModel) {
    this.$q
      .dialog({
        title: 'Confirm',
        message: 'Are you sure you want to delete?',
        cancel: true,
        persistent: true,
      })
      .onOk(() => {
        this.removeTask(task)
      })
  }

  onCloseModal() {
    this.selected = null
    this.taskId = ''
    this.modalTaskFormVisible = false
  }

  onEditTask(task: TaskModel) {
    if (!task._id) {
      return
    }

    this.selected = task
    this.taskId = task._id
  }
}
