<template>
  <CLoading v-if="loading" />
  <section v-if="!loading">
    <TaskListTable />
  </section>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'

import { Watch } from 'vue-property-decorator'
import { ACTION_COMMON } from '@/store/actions'

import TaskListTable from '@/components/task/TaskListTable.vue'
import TaskMixin from '@/components/task/mixins/TaskMixin.vue'
import logging from '@/utils/logging'
import CLoading from '@/components/common/ui/CLoading.vue'
import ProfileMixin from '@/components/account/mixins/ProfileMixin.vue'
import ProjectMixin from '@/components/project/mixins/ProjectMixin.vue'
import WorkspaceMixin from '@/components/workspace/mixins/WorkspaceMixin.vue'

@Options({
  components: { TaskListTable, CLoading },
  directives: { maska },
})
export default class Tasks extends mixins(ProfileMixin, ProjectMixin, WorkspaceMixin, TaskMixin) {
  isComponentReady = false

  get loading() {
    return !this.isComponentReady
  }

  get projectId() {
    return this.$route.params.projectId as string
  }

  get workspace() {
    return this.$store.getters.workspace
  }

  get project() {
    return this.$store.getters.project
  }

  @Watch('project')
  projectChanged() {
    if (!this.workspace?.title || !this.project?.title) {
      return false
    }

    this.$meta.setMeta({ title: [this.project.title, this.workspace.title] })
  }

  @Watch('$store.state.task.loading')
  tasksToDisplayChanged(newVal: boolean, oldVal: boolean) {
    if (oldVal && !newVal) {
      this.isComponentReady = true
    }
  }

  @Watch('workspace', { immediate: true })
  async workspaceIdChanged() {
    if (!this.workspace?._id) {
      return
    }

    if (!this.workspace?.canRead) {
      this.$store.dispatch(ACTION_COMMON.SET_NOT_FOUND)
    }
  }

  @Watch('projectId', { immediate: true })
  async projectIdChanged() {
    if (!this.projectId) {
      return
    }

    // [ProjectMixin]
    await this.getProject(this.projectId)

    // [TaskMixin]
    await this.getProjectTasks(this.projectId)
    this.isComponentReady = true
  }

  beforeMount() {
    logging.debugRender(Tasks.name)
  }
}
</script>
