<template>
  <q-btn-dropdown
    v-if="options.length"
    flat
    no-caps
    align="left"
    :label="label"
    color="white"
    :text-color="hasSelected ? 'orange' : 'black'"
    size="md"
    menu-anchor="top left"
    menu-self="top left"
    padding="5px 10px"
    :dropdown-icon="icon"
  >
    <q-list separator dense style="min-width: 200px" class="q-list--no-padding">
      <q-item
        clickable
        v-ripple
        v-for="option in options"
        :key="option.label"
        @click="toggleSelect(option)"
        :active="value.includes(option.name)"
      >
        <q-item-section>{{ option.label }}</q-item-section>
      </q-item>
    </q-list>
  </q-btn-dropdown>
</template>

<script lang="ts">
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { taskFieldsDisplayInTable } from '@/constants/task-fields'
import { ICustomField } from '@/components/custom-field/custom-field-model'
import CustomFieldMixin from '@/components/custom-field/mixins/CustomFieldMixin.vue'

interface IHiddenColumnOption {
  name: string
  label: string
}

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:change'],
})
export default class FilterHiddenTaskColumns extends mixins(CustomFieldMixin) {
  @Prop({ default: [] })
  modelValue!: string[]

  @Prop({ default: '' })
  label!: string

  @Prop()
  icon!: string

  get value() {
    return this.modelValue
  }

  set value(value: string[]) {
    this.$emit('update:modelValue', value)
    this.$emit('update:change', value)
  }

  get hasSelected() {
    return this.value.length
  }

  get options() {
    return (
      // [CustomFieldMixin]
      taskFieldsDisplayInTable(this.customFieldsToDisplayInTalble).filter(
        (field: ICustomField) => field.name !== 'title'
      )
    )
  }

  toggleSelect(option: IHiddenColumnOption) {
    const value = this.value
    if (!value?.length) {
      this.value = []
    }

    const index = value.indexOf(option.name as string)

    if (index >= 0) {
      value.splice(index, 1)
    } else {
      value.push(option.name as string)
    }

    this.value = value
  }
}
</script>
