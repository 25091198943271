<template>
  <TopbarTasksFilter v-model="filteredTasks" />
  <section class="task-list-container">
    <template v-for="state in workflowStates" :key="state.value">
      <div class="task-list-grid q-mt-lg">
        <div class="task-list-grid__row items-center">
          <!-- Headings -->
          <div
            v-for="column in visibleColumns"
            :key="column.name"
            :class="
              `row whitespace-nowrap items-center c-animate task-list-grid--heading task-list-grid__row-item task-list-grid__row-item--${column.name}`
            "
          >
            <template v-if="column.name === 'title'">
              <span class="text-weight-medium">{{ state.label }}</span>
              <q-badge
                rounded
                text-color="white"
                class="q-ml-xs"
                :style="{ background: state?.color }"
                :label="tasksInWorkflow[state.value]?.length || 0"
              ></q-badge>

              <q-space />
              <q-btn padding="0" color="grey" flat icon="arrow_drop_down" v-if="workspace?.canWrite">
                <q-menu anchor="center right" self="center right">
                  <q-list dense>
                    <q-item clickable v-ripple @click="$goto('workspace_board.workflows')">
                      <q-item-section> Workflows setting </q-item-section>
                    </q-item>
                  </q-list>
                </q-menu>
              </q-btn>
            </template>
            <div v-else>
              {{ column.label }}
            </div>
          </div>
        </div>

        <!-- Body -->
        <div v-if="!tasksInWorkflow[state.value]?.length" class="text-grey-5 text-italic q-pl-md">No tasks</div>

        <draggable
          :list="tasksInWorkflow[state.value]"
          :component-data="{
            group: state.value,
            type: 'transition-group',
            name: 'drag-area',
          }"
          :forceFallback="true"
          itemKey="position"
          v-bind="{
            animation: 200,
            group: 'task',
            disabled: false,
          }"
          handle=".cursor-move"
          fallbackClass="dragging-task-item"
          @change="evt => onDragEnd(evt, tasksInWorkflow[state.value], state.value)"
          @start="dragging = true"
          @end="dragging = false"
        >
          <template #item="{ element: task }">
            <div class="task-list-grid__row items-center">
              <div
                v-for="column in visibleColumns"
                :key="column.name"
                :class="{
                  [`task-list-grid--body task-list-grid__row-item task-list-grid__row-item--${column.name}`]: true,
                  'is-new': !task.isSeen,
                }"
              >
                <!-- Task title -->
                <div
                  v-if="column.name === 'title'"
                  @click="onEditTask(task)"
                  class="cursor-pointer c-hover-trans-up w-full"
                >
                  <div class="row items-center no-wrap q-gutter-sm">
                    <q-btn flat padding="0" color="grey" size="sm" class="cursor-move hide-on-dragging">
                      <q-icon name="drag_indicator" />
                    </q-btn>
                    <span class="ellipsis flex-grow">
                      {{ task.title }}
                    </span>
                    <TaskCommentCountBadge :task="task" />
                  </div>
                </div>

                <!-- Creator -->
                <UserBox v-if="column.name === 'creator'" :user="task.creator" :size="25" />

                <!-- custom fields -->
                <div v-if="isCustomField(column.name)">
                  <component
                    :is="customFieldComponentsViewList[column.type]"
                    :value="task.customFieldsVal[column.name]"
                    v-bind="{ customField: column, showLabel: false }"
                  />
                </div>

                <!-- updatedAt -->
                <div v-if="column.name === 'updatedAt'">
                  <span v-if="task.updatedAt" class="text-grey">
                    {{ formatDate(task.updatedAt) }}
                  </span>
                </div>

                <!-- action -->
                <div v-if="column.name === 'action'">
                  <q-btn icon="more_horiz" padding="xs" flat class="text-grey-5">
                    <q-menu class="max-w-reset">
                      <q-list dense>
                        <q-item clickable v-close-popup @click="onDeleteTask(task)">
                          <q-item-section> Delete task </q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </div>
            </div>
          </template>
        </draggable>
      </div>
    </template>
  </section>

  <TaskViewModal
    v-if="modalTaskFormVisible"
    :taskData="selected"
    :modalVisible="modalTaskFormVisible"
    @update:closeModal="onCloseModal"
  />
</template>

<script lang="ts">
import { Watch } from 'vue-property-decorator'
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ICustomField } from '@/components/custom-field/custom-field-model'
import { ETaskState, TaskModel } from '@/components/task/task-model'
import { ProjectModel } from '../project/project-model'
import { isCustomField } from '@/utils/helpers'
import { IWorkflowState } from '@/components/workflow/workflow-model'
import { UNKNOWN_WORKFLOW_STATE } from '@/constants/vars'
import { WorkspaceModel } from '../workspace/workspace-model'

import TaskViewModal from '@/components/task/TaskViewModal.vue'
import TaskDisplayMixin from './mixins/TaskDisplayMixin.vue'
import TopbarTasksFilter from './ui/TopbarTasksFilter.vue'
import CustomFieldMixin from '../custom-field/mixins/CustomFieldMixin.vue'
import Empty from '../common/ui/Empty.vue'
import logging from '@/utils/logging'
import UserBox from '../user/ui/UserBox.vue'
import draggable from 'vuedraggable'
import dayjs from 'dayjs'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import TaskCommentCountBadge from './ui/TaskCommentCountBadge.vue'

@Options({
  components: {
    draggable,
    Empty,
    TopbarTasksFilter,
    TaskViewModal,
    UserBox,
    TaskCommentCountBadge,
  },
  directives: { maska },
})
export default class TaskListTable extends mixins(TaskDisplayMixin, CustomFieldMixin) {
  isCustomField = isCustomField
  tasksInWorkflow: Record<string, TaskModel[]> = {}

  get workflowStates(): IWorkflowState[] {
    return this.project?.workflow?.states || [UNKNOWN_WORKFLOW_STATE]
  }

  get project(): ProjectModel {
    return this.$store.getters.project
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get columns() {
    return [
      {
        label: 'Title',
        name: 'title',
      },
      // [CustomFieldMixin]
      ...this.customFieldsToDisplayInTalble,
      {
        label: 'Creator',
        name: 'creator',
      },
      {
        label: 'Last updated',
        name: 'updatedAt',
      },
      {
        label: '#',
        name: 'action',
      },
    ]
  }

  get visibleColumns() {
    const cols: ICustomField[] = []
    this.columns.forEach(col => {
      if (!this.filteredTasks.hiddenColumns.includes(col.name || '')) {
        cols.push(col)
      }
    })

    return cols
  }

  // [TaskDisplayFilterMixin]
  @Watch('tasksFiltered', { immediate: true })
  tasksFilteredChanged() {
    this.setTasksInWorkflow()
  }

  shouldShowUnknownTasksCol(state: IWorkflowState) {
    if (state.value === UNKNOWN_WORKFLOW_STATE.value && !this.tasksInWorkflow[state.value]?.length) {
      return false
    }

    return true
  }

  getInitTaskInWorkstate() {
    const tasksInWorkflow: Record<string, TaskModel[]> = {}
    for (const state of this.workflowStates) {
      tasksInWorkflow[state.value] = []
    }

    return tasksInWorkflow
  }

  setTasksInWorkflow() {
    const tasksInWorkflow = this.getInitTaskInWorkstate()
    const processedTasksId: string[] = []
    for (const task of this.tasksFiltered) {
      if (!task.state || !task._id || typeof tasksInWorkflow[task.state] === 'undefined') {
        continue
      }

      tasksInWorkflow[task.state].push(task)
      processedTasksId.push(task._id)
    }

    const unknownTasks: TaskModel[] = this.tasksFiltered.filter(
      task => task._id && !processedTasksId.includes(task._id)
    )

    const firstWorkflowState: IWorkflowState = this.workflowStates[0]
    if (unknownTasks.length && firstWorkflowState) {
      tasksInWorkflow[firstWorkflowState.value] = tasksInWorkflow[firstWorkflowState.value].concat(unknownTasks)
    }

    this.tasksInWorkflow = cloneDeep(this.sortTaskInWorkflow(tasksInWorkflow))
  }

  sortTaskInWorkflow(data: Record<string, TaskModel[]>) {
    for (const [key, value] of Object.entries(data)) {
      data[key] = value.sort((a, b) => (a.position || 0) - (b.position || 0))
    }

    return data
  }

  // eslint-disable-next-line
  onDragEnd(evt: any, draggedTasksList: TaskModel[], stateValue: ETaskState) {
    if (evt.added) {
      // [TaskDisplayMixin]
      this.updateTaskOnDragged(evt.added.element, draggedTasksList, stateValue)
    } else if (evt.moved) {
      // [TaskDisplayMixin]
      this.updateTaskOnDragged(evt.moved.element, draggedTasksList, stateValue)
    }
  }

  /**
   * Position is calculate by prevItem.position + (prevItem.position + nextItem.position)/2
   */
  updateTaskOnDragged(task: TaskModel, list: TaskModel[], stateValue: ETaskState) {
    const dragToIndex = findIndex(list, (item: TaskModel) => item._id === task._id)
    const prevPosition = list[dragToIndex - 1]?.position || 0
    const nextPosition = list[dragToIndex + 1]?.position || dayjs().unix()
    const finalPosition = prevPosition + Math.round((nextPosition - prevPosition) / 2)

    // [TaskMixin]
    this.updateTask({ _id: task._id, position: finalPosition, state: stateValue })
  }

  created() {
    logging.debugRender(TaskListTable.name)
  }
}
</script>
