
import { Options, mixins } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { taskFieldsDisplayInTable } from '@/constants/task-fields'
import { ICustomField } from '@/components/custom-field/custom-field-model'
import CustomFieldMixin from '@/components/custom-field/mixins/CustomFieldMixin.vue'

interface IHiddenColumnOption {
  name: string
  label: string
}

@Options({
  components: {},
  directives: { maska },
  emits: ['update:modelValue', 'update:change'],
})
export default class FilterHiddenTaskColumns extends mixins(CustomFieldMixin) {
  @Prop({ default: [] })
  modelValue!: string[]

  @Prop({ default: '' })
  label!: string

  @Prop()
  icon!: string

  get value() {
    return this.modelValue
  }

  set value(value: string[]) {
    this.$emit('update:modelValue', value)
    this.$emit('update:change', value)
  }

  get hasSelected() {
    return this.value.length
  }

  get options() {
    return (
      // [CustomFieldMixin]
      taskFieldsDisplayInTable(this.customFieldsToDisplayInTalble).filter(
        (field: ICustomField) => field.name !== 'title'
      )
    )
  }

  toggleSelect(option: IHiddenColumnOption) {
    const value = this.value
    if (!value?.length) {
      this.value = []
    }

    const index = value.indexOf(option.name as string)

    if (index >= 0) {
      value.splice(index, 1)
    } else {
      value.push(option.name as string)
    }

    this.value = value
  }
}
