
import { Options, Vue } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IUserWithPermission } from '@/components/user/user-model'
import UsersAvatar from '@/components/user/ui/UsersAvatar.vue'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import FilterHiddenTaskColumns from './FilterHiddenTaskColumns.vue'
import FilterUserInput from '../../user/ui/FilterUserInput.vue'
import WorkspaceTopBar from '../../workspace/ui/WorkspaceTopBar.vue'
import logging from '@/utils/logging'

@Options({
  components: {
    WorkspaceTopBar,
    FilterUserInput,
    FilterHiddenTaskColumns,
    UserAvatar,
    UsersAvatar,
  },
  directives: { maska },
  emits: ['update:modelValue'],
})
export default class TopbarTasksFilter extends Vue {
  @Prop({ default: {} })
  modelValue!: string

  @Prop({ default: true })
  showHiddenFilter!: boolean

  get value() {
    return this.modelValue
  }

  get projectMembers(): IUserWithPermission[] {
    return this.$store.getters.project?.members || []
  }

  created() {
    logging.debugRender(TopbarTasksFilter.name)
  }
}
