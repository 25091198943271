import { render } from "./WorkflowStateSelect.vue?vue&type=template&id=ae83952a"
import script from "./WorkflowStateSelect.vue?vue&type=script&lang=ts"
export * from "./WorkflowStateSelect.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtn from 'quasar/src/components/btn/QBtn.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QMenu from 'quasar/src/components/menu/QMenu.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtn,QIcon,QMenu,QList,QItem});qInstall(script, 'directives', {Ripple});
