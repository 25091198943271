
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IUser } from '@/components/user/user-model'
import UserAvatar from './UserAvatar.vue'

@Options({
  components: { UserAvatar },
})
export default class UserBox extends Vue {
  @Prop({ default: '' })
  user!: IUser

  @Prop({ default: 40 })
  size!: number

  @Prop({ default: false })
  square!: boolean
}
