
import { Options, Vue } from 'vue-class-component'
import { maska } from 'maska'
import { WorkspaceModel } from '@/components/workspace/workspace-model'
import { IUserWithPermission } from '@/components/user/user-model'

import uniqBy from 'lodash/uniqBy'
import UsersAvatar from '@/components/user/ui/UsersAvatar.vue'
import logging from '@/utils/logging'

@Options({
  components: {
    UsersAvatar,
  },
  directives: { maska },
})
export default class WorkspaceTopBar extends Vue {
  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace || {}
  }

  get projectMembers(): IUserWithPermission[] {
    return this.$store.getters.project?.members || []
  }

  get workspaceMembers(): IUserWithPermission[] {
    const workspaceMembers = this.workspace.members || []
    const members = workspaceMembers.concat(this.projectMembers)
    return uniqBy(members, '_id')
  }

  get canWriteTask() {
    return this.$store.getters.projects(this.workspaceId)?.length
  }

  created() {
    logging.debugRender(WorkspaceTopBar.name)
  }
}
