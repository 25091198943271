
import { Watch } from 'vue-property-decorator'
import { Options, mixins } from 'vue-class-component'
import { maska } from 'maska'
import { ICustomField } from '@/components/custom-field/custom-field-model'
import { ETaskState, TaskModel } from '@/components/task/task-model'
import { ProjectModel } from '../project/project-model'
import { isCustomField } from '@/utils/helpers'
import { IWorkflowState } from '@/components/workflow/workflow-model'
import { UNKNOWN_WORKFLOW_STATE } from '@/constants/vars'
import { WorkspaceModel } from '../workspace/workspace-model'

import TaskViewModal from '@/components/task/TaskViewModal.vue'
import TaskDisplayMixin from './mixins/TaskDisplayMixin.vue'
import TopbarTasksFilter from './ui/TopbarTasksFilter.vue'
import CustomFieldMixin from '../custom-field/mixins/CustomFieldMixin.vue'
import Empty from '../common/ui/Empty.vue'
import logging from '@/utils/logging'
import UserBox from '../user/ui/UserBox.vue'
import draggable from 'vuedraggable'
import dayjs from 'dayjs'
import findIndex from 'lodash/findIndex'
import cloneDeep from 'lodash/cloneDeep'
import TaskCommentCountBadge from './ui/TaskCommentCountBadge.vue'

@Options({
  components: {
    draggable,
    Empty,
    TopbarTasksFilter,
    TaskViewModal,
    UserBox,
    TaskCommentCountBadge,
  },
  directives: { maska },
})
export default class TaskListTable extends mixins(TaskDisplayMixin, CustomFieldMixin) {
  isCustomField = isCustomField
  tasksInWorkflow: Record<string, TaskModel[]> = {}

  get workflowStates(): IWorkflowState[] {
    return this.project?.workflow?.states || [UNKNOWN_WORKFLOW_STATE]
  }

  get project(): ProjectModel {
    return this.$store.getters.project
  }

  get workspace(): WorkspaceModel {
    return this.$store.getters.workspace
  }

  get columns() {
    return [
      {
        label: 'Title',
        name: 'title',
      },
      // [CustomFieldMixin]
      ...this.customFieldsToDisplayInTalble,
      {
        label: 'Creator',
        name: 'creator',
      },
      {
        label: 'Last updated',
        name: 'updatedAt',
      },
      {
        label: '#',
        name: 'action',
      },
    ]
  }

  get visibleColumns() {
    const cols: ICustomField[] = []
    this.columns.forEach(col => {
      if (!this.filteredTasks.hiddenColumns.includes(col.name || '')) {
        cols.push(col)
      }
    })

    return cols
  }

  // [TaskDisplayFilterMixin]
  @Watch('tasksFiltered', { immediate: true })
  tasksFilteredChanged() {
    this.setTasksInWorkflow()
  }

  shouldShowUnknownTasksCol(state: IWorkflowState) {
    if (state.value === UNKNOWN_WORKFLOW_STATE.value && !this.tasksInWorkflow[state.value]?.length) {
      return false
    }

    return true
  }

  getInitTaskInWorkstate() {
    const tasksInWorkflow: Record<string, TaskModel[]> = {}
    for (const state of this.workflowStates) {
      tasksInWorkflow[state.value] = []
    }

    return tasksInWorkflow
  }

  setTasksInWorkflow() {
    const tasksInWorkflow = this.getInitTaskInWorkstate()
    const processedTasksId: string[] = []
    for (const task of this.tasksFiltered) {
      if (!task.state || !task._id || typeof tasksInWorkflow[task.state] === 'undefined') {
        continue
      }

      tasksInWorkflow[task.state].push(task)
      processedTasksId.push(task._id)
    }

    const unknownTasks: TaskModel[] = this.tasksFiltered.filter(
      task => task._id && !processedTasksId.includes(task._id)
    )

    const firstWorkflowState: IWorkflowState = this.workflowStates[0]
    if (unknownTasks.length && firstWorkflowState) {
      tasksInWorkflow[firstWorkflowState.value] = tasksInWorkflow[firstWorkflowState.value].concat(unknownTasks)
    }

    this.tasksInWorkflow = cloneDeep(this.sortTaskInWorkflow(tasksInWorkflow))
  }

  sortTaskInWorkflow(data: Record<string, TaskModel[]>) {
    for (const [key, value] of Object.entries(data)) {
      data[key] = value.sort((a, b) => (a.position || 0) - (b.position || 0))
    }

    return data
  }

  // eslint-disable-next-line
  onDragEnd(evt: any, draggedTasksList: TaskModel[], stateValue: ETaskState) {
    if (evt.added) {
      // [TaskDisplayMixin]
      this.updateTaskOnDragged(evt.added.element, draggedTasksList, stateValue)
    } else if (evt.moved) {
      // [TaskDisplayMixin]
      this.updateTaskOnDragged(evt.moved.element, draggedTasksList, stateValue)
    }
  }

  /**
   * Position is calculate by prevItem.position + (prevItem.position + nextItem.position)/2
   */
  updateTaskOnDragged(task: TaskModel, list: TaskModel[], stateValue: ETaskState) {
    const dragToIndex = findIndex(list, (item: TaskModel) => item._id === task._id)
    const prevPosition = list[dragToIndex - 1]?.position || 0
    const nextPosition = list[dragToIndex + 1]?.position || dayjs().unix()
    const finalPosition = prevPosition + Math.round((nextPosition - prevPosition) / 2)

    // [TaskMixin]
    this.updateTask({ _id: task._id, position: finalPosition, state: stateValue })
  }

  created() {
    logging.debugRender(TaskListTable.name)
  }
}
