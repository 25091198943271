<template>
  <template v-if="user">
    <div class="whitespace-nowrap row items-center no-wrap q-gutter-sm max-w-full-pt">
      <UserAvatar :user="user" :size="size" :square="square" />
      <span class="ellipsis">
        {{ user.displayName }}
      </span>
    </div>
  </template>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { IUser } from '@/components/user/user-model'
import UserAvatar from './UserAvatar.vue'

@Options({
  components: { UserAvatar },
})
export default class UserBox extends Vue {
  @Prop({ default: '' })
  user!: IUser

  @Prop({ default: 40 })
  size!: number

  @Prop({ default: false })
  square!: boolean
}
</script>
