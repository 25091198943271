import { render } from "./FilterHiddenTaskColumns.vue?vue&type=template&id=028a5836"
import script from "./FilterHiddenTaskColumns.vue?vue&type=script&lang=ts"
export * from "./FilterHiddenTaskColumns.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QBtnDropdown from 'quasar/src/components/btn-dropdown/QBtnDropdown.js';
import QList from 'quasar/src/components/item/QList.js';
import QItem from 'quasar/src/components/item/QItem.js';
import QItemSection from 'quasar/src/components/item/QItemSection.js';import Ripple from 'quasar/src/directives/Ripple.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QBtnDropdown,QList,QItem,QItemSection});qInstall(script, 'directives', {Ripple});
