
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import { IUser } from '@/components/user/user-model'
import UserAvatar from './UserAvatar.vue'

@Options({
  components: { UserAvatar },
  directives: { maska },
})
export default class UsersAvatar extends Vue {
  @Prop({ default: [] })
  users!: IUser[]

  @Prop({ default: 3 })
  displayCount!: number

  @Prop({ default: 30 })
  size!: number

  get usersDisplay() {
    return this.users?.slice(0, this.displayCount)
  }

  get remainCount() {
    return this.users.length - this.displayCount
  }
}
