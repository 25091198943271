import { render } from "./TopbarTasksFilter.vue?vue&type=template&id=77b427f0"
import script from "./TopbarTasksFilter.vue?vue&type=script&lang=ts"
export * from "./TopbarTasksFilter.vue?vue&type=script&lang=ts"
script.render = render

export default script
import QTabs from 'quasar/src/components/tabs/QTabs.js';
import QInput from 'quasar/src/components/input/QInput.js';
import QField from 'quasar/src/components/field/QField.js';
import QIcon from 'quasar/src/components/icon/QIcon.js';
import QSpace from 'quasar/src/components/space/QSpace.js';
import QSeparator from 'quasar/src/components/separator/QSeparator.js';
import qInstall from "../../../../node_modules/vue-cli-plugin-quasar/lib/runtime.auto-import.js";
qInstall(script, 'components', {QTabs,QInput,QField,QIcon,QSpace,QSeparator});
