<template>
  <q-btn
    outline
    rounded
    no-caps
    align="left"
    :label="label"
    :icon="icon"
    color="white"
    text-color="black"
    size="md"
    menu-anchor="top left"
    menu-self="top left"
    padding="5px 10px"
  >
    <q-badge v-if="selectedCount" color="orange" floating>{{ selectedCount }}</q-badge>
    <q-menu>
      <q-list separator dense style="min-width: 200px">
        <q-item
          clickable
          v-ripple
          v-for="user in users"
          :key="user._id"
          @click="toggleSelect(user)"
          :active="value.includes(user._id)"
        >
          <q-item-section avatar>
            <UserAvatar :user="user" :size="24" />
          </q-item-section>
          <q-item-section>{{ user.displayName }}</q-item-section>
          <q-item-section side v-if="value.includes(user._id)">
            <q-icon name="check" color="green" />
          </q-item-section>
        </q-item>
      </q-list>
    </q-menu>
  </q-btn>
</template>

<script lang="ts">
import { Vue, Options } from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { maska } from 'maska'
import UserAvatar from '@/components/user/ui/UserAvatar.vue'
import { IUser } from '@/components/user/user-model'

@Options({
  components: { UserAvatar },
  directives: { maska },
  emits: ['update:modelValue', 'update:change'],
})
export default class FilterUserInput extends Vue {
  @Prop({ default: [] })
  modelValue!: string[]

  @Prop({ default: [] })
  users!: IUser[]

  @Prop({ default: 'Users' })
  label!: string

  @Prop()
  icon!: string

  get value() {
    return this.modelValue
  }

  set value(value: string[]) {
    this.$emit('update:modelValue', value)
    this.$emit('update:change', value)
  }

  get workspaceId() {
    return this.$route.params.workspaceId
  }

  get selectedCount() {
    return this.value.length
  }

  toggleSelect(user: IUser) {
    const value = this.value
    if (!value?.length) {
      this.value = []
    }

    const index = value.indexOf(user._id as string)

    if (index >= 0) {
      value.splice(index, 1)
    } else {
      value.push(user._id as string)
    }

    this.value = value
  }
}
</script>
